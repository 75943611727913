<template>
  <!-- <UidDesktop v-if="!isMobileAgent()"/>
    <UidMobile v-else/> -->
  <UidDesktop />
</template>

<script>
export default {
  name: "Uid",
  components: {
    UidDesktop: () => import("@/components/pos_goods/Uid"),
    // UidMobile: () => import('@/components/pos_goods/UidMobile'),
  },
};
</script>

<style scoped></style>
